import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="Header">
        <h1>TENGU</h1>
        <br/>
          <code>
            Tengu is a third person action game, you'll be a member of a settlement struggling to survive, 
            looking for materials that have become scarce.
            Choose from a variety of combat styles, join us on this thrilling journey and see if you have what it takes to keep your settlement alive.
          </code>   
      </header>
    </div>
  );
}

export default App;
